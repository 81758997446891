export const Style = {
  main: {
    // mt: { xs: "20px", sm: "0px" },
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
  },
  topBannerContent2: {
    color: "#4B4B4B",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "22px",
  },
  tableContainer: (isDrawer) => ({
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    py: "10px",
    boxSizing: "border-box",
    gap: { xs: "20px", md: "40px", lg: "60px" },
    minWidth: "223px",
    // maxWidth:  { xs: "100vw", sm: isDrawer ? "calc(100vw - 282px)" : "calc(100vw - 108px)", md: isDrawer ? "calc(100vw - 305px)" : "calc(100vw - 90px)", lg: isDrawer ? "calc(100vw - 280px)" : "calc(100vw - 100px)", xl: "1700px" },
    flexWrap: "wrap",
    width: "100%",
    height: {
      xs: "fit-content",
    },
  }),
  btn: {
    boxSizing: "border-box",
    mt: { xs: 2, md: 0 },
    width: { xs: "100%", md: "50%" },
    maxWidth: "250px",
    height: "44px",
    display: "flex",
  },
  header: (isFull) => ({
    background: "#1E2265",
    color: "#fff",
    // mx: { xs: 2 },
    // mt: { xs: "-16px", sm: "0px", md: "0px", xl: "0px" },
    // pt: "16px",
    ml: isFull ? 0 : { xs: -3, sm: -3.25, md: -4.25, xl: "-40px" },
    width: isFull
      ? "100%"
      : {
          xs: "calc(100% + 47px)",
          sm: "calc(100% + 52px)",
          md: "calc(100% + 70px)",
          xl: "calc(100% + 96px)",
        },
    zIndex: 1,
    minHeight: { xs: "50px", md: "144px" },
    display: "flex",
    // gap: "16px",
    alignItems: "center",
    // justifyContent: { xs: "space-between" },
    flexWrap: { xs: "nowrap", sm: "nowrap" },
    px: { xs: 2.5, sm: 5, md: 6.4 },
    py: 2,
    borderBottom: "17px solid #D6DAFD",
    borderRadius: "8px 8px 0 0",
  }),
  trainingHeading: {
    color: "#FFF",
    fontFamily: "Rubik",
    fontSize: { xs: "18px", sm: "25px", md: "28px" },
    fontWeight: "400",
    lineHeight: { xs: "24px", sm: "30px", md: "36px" },
  },
  trainingName: {
    color: "#FFF",
    fontFamily: "Rubik",
    fontSize: { xs: "18px", sm: "25px", md: "28px" },
    fontWeight: "600",
    lineHeight: { xs: "24px", sm: "30px", md: "36px" },
    ml: 0.75,
  },
  timeMain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 1,
  },
  timeHeading: {
    pr: 1,
    color: "#1F1F1F",
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: "400",
  },
  timeContainer: {
    display: "flex",
    gap: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  timeNo: {
    borderRadius: "4px",
    background: "#1D8984",
    color: "#fff",
    width: "38px",
    height: "38px",
    flexShrink: "0",
    color: "#FFF",
    fontFamily: "Rubik",
    fontSize: "17px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  timeDot: {
    color: "#272727",
    fontFamily: "Rubik",
    fontSize: "20px",
    fontWeight: "400",
  },
  searchWrapper: {
    p: "2px 10px 2px 6px",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    alignSelf: "end",
    width: { xs: "100%", lg: "50%" },
    mt: 2.5,
    minWidth: { xs: "100px", md: "340px" },
    // maxWidth:  '380px',
    borderRadius: "8px",
    boxShadow: "none",
    height: { xs: "40px", sm: "48px" },
  },
  inputBase: {
    ml: 1,
    flex: 1,
    fontFamily: "Rubik",
    fontSize: "18px",
    fontWeight: "400",
    opacity: "1",
    "& input": {
      width: "100%",
    },
    "& input::placeholder": {
      color: "#1F1F1F",
      fontSize: "18px",
      opacity: "0.8",
    },
  },
  addIcon: {
    width: "20px",
  },
  tabContainer: {
    width: "100%",
    display: "flex",
    flexDirection: { xs: "column", lg: "row" },
    justifyContent: "space-between",
    gap: "16px",
    mt: 0.75,
  },
  iconButton: {
    p: "10px 5px",
  },
  search: {
    width: "21px",
    opacity: "0.8",
  },
  loader: {
    height: "unset",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    cursor: "pointer",
    width: "68px",
    height: "84px",
    objectFit: "contain",
    // maxWidth:  "100%",
    alignSelf: "flex-start",
    display: "flex",
    alignSelf: "start",
    mt: -0.75,
    mb: -2.25,
  },
};
